// mock-data.ts
import { Article } from './task.interface';
import {Task} from '../../agenda/@models/task.interface';

export const TASKS: Task[] = [
  {
    id: 1,
    title: 'Nom de la tâche',
    description: 'Description brève de la tâche',
    source: 'Source',
    timeline: 'Cette semaine',
    sourceIconUrl: '/assets/svg/hexagon.svg',
    timelineIconUrl: '/assets/svg/calendar.svg',
  },
];

export const ARTICLES: Article[] = [
  {
    title: 'Attention aux limaces',
    subTitle: 'Septembre, Octobre',
    imageUrl: '/assets/svg/greenIMG.svg',
    iconUrl: '',
    badges: [
      {
        iconUrl: '/assets/svg/hexagongreen.svg',
        label: 'Source',
        color: '#63AE81',
        borderColor: '#63AE81',
      },
      {
        iconUrl: '/assets/svg/hexagongreen.svg',
        label: 'Choux',
        color: '#63AE81',
        borderColor: '#63AE81',
      },
    ],
    dateRange: 'Septembre, Octobre',
  },
  // Additional advice items as needed
];

export const TASK_ITEMS: Task[] = [
  {
    id: 1,
    title: 'Tomates',
    description: '3 variétés',
    iconUrl: '/assets/svg/tomato-1.svg',
    infoIconUrl: '/assets/svg/info.svg',
    infoMessage: 'Action à réaliser',
    additionalInfoIconUrl: '/assets/svg/leaf2.svg',
    sourceIconUrl: '/assets/svg/hexagon.svg',
    timelineIconUrl: '/assets/svg/calendar.svg',
  },
  {
    id: 2,
    title: 'Laitues',
    description: 'Pas de variété renseignée',
    iconUrl: '/assets/svg/cobbage.svg',
    infoIconUrl: '/assets/svg/info.svg',
    infoMessage: 'Action à réaliser',
    sourceIconUrl: '/assets/svg/hexagon.svg',
    timelineIconUrl: '/assets/svg/calendar.svg',
  },
];
