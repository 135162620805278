import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {SharedModule} from '../@commons/ui-components/shared.module';
import {TaskComponent} from '../@commons/@components/task/task.component';
import {ArticleComponent} from '../@commons/@components/article/article.component';
import {HeaderHomeComponent} from './@components/header-home/header-home.component';
import {fadeIn} from '../@commons/animations/animations';
import {Article} from './@models/task.interface';
import {User} from '../user-profile/@models/accountStats.interface';
import {Router} from '@angular/router';
import {TaskService} from './@services/task.service';
import {UserProfileService} from '../user-profile/@sevices/user-profile.service';
import {PotagerService} from '../garden/@services/potager.service';
import routesConstants from '../@commons/constants/routes.constants';
import {Task} from '../agenda/@models/task.interface';
import {WeatherCardComponent} from './@components/weather-card/weather-card.component';
import {UserCultivationsComponent} from '../garden/user-cultivations/user-cultivations.component';
import {Cultivation} from '../garden/@models/cultivation.interface';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'vw-home', // Selector for the component to be used in templates
  standalone: true, // Indicates that this component is standalone
  imports: [
    SharedModule,
    CommonModule,
    UserCultivationsComponent,
    WeatherCardComponent,
    TaskComponent,
    ArticleComponent,
    HeaderHomeComponent,
    TranslateModule,
  ], // Imports shared and common modules
  templateUrl: './home.component.html', // Path to the component's template
  styleUrls: ['./home.component.scss'], // Path to the component's styles
  animations: [fadeIn],
})
export class HomeComponent implements OnInit {
  tasks: Task[] = []; // Array to hold tasks
  isModalOpen: boolean = false; // State variable to manage modal visibility
  cultivations: Cultivation[] = []; // Array to hold the list of plants
  articles: Article[] = []; // Array to hold badge information
  user!: User;

  // Example data for the cards
  cards = [
    {
      label: '19°c',
      imageUrl: '/assets/svg/cloud-rain.svg',
      title: 'Pas besoin d’arroser aujourd’hui',
      tailwindClasses: 'bg-[#E1EDFE]',
      timeLabelStyle: 'text-[#0F453B]',
      expanded: false,
      weatherImg: '/assets/svg/weather-tree.svg',
      WeatherForecastLabel: 'text-[#1A4166]',
    },
    {
      label: '19°c',
      imageUrl: '/assets/svg/cloud-rain-white.svg',
      title: 'Pas besoin d’arroser aujourd’hui',
      tailwindClasses: 'bg-[#205280]',
      timeLabelStyle: 'text-[#FFFFFFB2]',
      expanded: false,
      weatherImg: '/assets/svg/weather-tree.svg',
      WeatherForecastLabel: 'text-[#FFFFFF]',
    },
    {
      label: '24°c',
      imageUrl: '/assets/svg/sun-white.svg',
      title: 'Pas besoin d’arroser aujourd’hui',
      tailwindClasses: 'bg-[#F4D537]',
      timeLabelStyle: 'text-[#FFFFFFB2]',
      expanded: false,
      weatherImg: '/assets/svg/weather-tree.svg',
      WeatherForecastLabel: 'text-[#FFFFFF]',
    },
    {
      label: '24°c',
      imageUrl: '/assets/svg/sun.svg',
      title: 'Pas besoin d’arroser aujourd’hui',
      tailwindClasses: 'bg-[#F49F37]',
      timeLabelStyle: 'text-[#0F453B]',
      expanded: false,
      weatherImg: '/assets/svg/weather-tree.svg',
      WeatherForecastLabel: 'text-[#1A4166]',
    },
    {
      label: 'Dernier Quartier ',
      imageUrl: '/assets/svg/moon.svg',
      title: 'Ne pas jardiner',
      tailwindClasses: 'bg-[#382E96]',
      timeLabelStyle: 'text-[#DAF2E0]',
      expanded: false,
      weatherImg: '/assets/svg/moon-tree.svg',
      WeatherForecastLabel: 'text-[#FFFFFF]',
    },
  ];

  constructor(
    private router: Router,
    private taskService: TaskService,
    private compteService: UserProfileService,
    private potagerService: PotagerService
  ) {} // Constructor to inject the TaskService

  ngOnInit() {
    // Fetch tasks, badge information, and task items on component initialization
    this.taskService.getTasks().subscribe((data) => {
      this.tasks = data; // Assign fetched tasks to the tasks array
    });
    this.taskService.getSectionData().subscribe((data) => {
      this.articles = data; // Assign fetched badge info to the BadgeInfo array
    });
    this.compteService.getUserDetails().subscribe((data) => {
      this.user = data; // Assign fetched user details to the user property
    });
    this.potagerService.getCultivations('home').subscribe((cultivations) => {
      this.cultivations = cultivations; // Assign the fetched plants to the component property
    });
  }

  /**
   * Emits a profile click event to the parent component.
   */
  onProfileClick() {
    this.router.navigate([routesConstants.compte]);
  }

  /**
   * Opens the modal by setting isModalOpen to true.
   */
  openModal() {
    this.isModalOpen = true; // Open the modal
  }

  /**
   * Closes the modal by setting isModalOpen to false.
   */
  closeModal() {
    this.isModalOpen = false; // Close the modal
  }

  /**
   * Logs a message when the modal is closed and invokes closeModal.
   */
  onModalClose() {
    console.log('Modal has been closed'); // Log the modal close event
    this.closeModal(); // Close the modal
  }

  /**
   * Logs a confirmation message and closes the modal.
   */
  confirmAction() {
    console.log('Action confirmed!'); // Log confirmation action
    this.closeModal(); // Close the modal
  }

  // Card click event handler
  onCardClick(card: any) {
    console.log('Card clicked!', card);
  }

  // Handle card toggle event (expand/collapse)
  onCardToggle(expanded: boolean, index: number) {
    this.cards[index].expanded = expanded;
    console.log(`Card ${index + 1} expanded state:`, expanded);
  }
}
