import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SharedModule} from '../../../@commons/ui-components/shared.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';


@Component({
  selector: 'vw-app-menu', // Selector for the bottom bar component
  standalone: true, // Indicates that this component is standalone
  imports: [SharedModule, CommonModule, TranslateModule], // Imports required modules for the component
  templateUrl: './app-menu.component.html', // Path to the component's HTML template
  styleUrl: './app-menu.component.scss' // Path to the component's SCSS styles
})
export class AppMenuComponent {
  @Input() currentScreen: string = 'home';
  @Output() screenChange = new EventEmitter<string>(); // Event emitter to notify parent component of screen changes

  /**
   * Changes the current screen and emits the new screen name to parent components.
   * @param screen - The name of the screen to switch to.
   */
  changeScreen(screen: string) {
    this.currentScreen = screen; // Update the current screen
    this.screenChange.emit(screen); // Emit the new screen name to the parent component
  }
  onClickProfile(){
    this.changeScreen('profile');
  }
}
