import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {TaskComponent} from '../../@commons/@components/task/task.component';
import {SharedModule} from '../../@commons/ui-components/shared.module';
import {Task} from '../@models/task.interface';
import {Article} from '../../home/@models/task.interface';
import {AgendaService} from '../@services/agenda.service';

@Component({
  selector: 'vw-agenda-task', // Component selector for app-agenda-task
  standalone: true, // Indicates the component is standalone
  imports: [CommonModule, SharedModule, TranslateModule, TaskComponent], // Modules required by this component
  templateUrl: './agenda-task.component.html', // HTML template for the component
  styleUrl: './agenda-task.component.scss' // Stylesheet for the component
})
export class AgendaTaskComponent {

  /**
   * List of agenda tasks.
   * @type {Task[]}
   */
  tasks: Task[] = [];

  /**
   * ID of the currently active task.
   * @type {number | null}
   */
  activeTaskId: number | null = null;

  /**
   * List of advices related to the agenda.
   * @type {Article[]}
   */
  Advices: Article[] = [];

  /**
   * Initializes the AgendaTaskComponent with required services.
   * @param {AgendaService} agendaService - Service to fetch agenda-related data
   */
  constructor(private agendaService: AgendaService) {}

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   * Subscribes to tasks and advices data from AgendaService.
   */
  ngOnInit() {
    this.agendaService.getAgendaTasks().subscribe((tasks) => {
      this.tasks = tasks;
    });

    this.agendaService.getAgendaAdvice().subscribe((advices) => {
      this.Advices = advices;
    });
  }

  /**
   * Sets the selected task, ensuring only one task is active at a time.
   * @param {number} taskId - ID of the task to be set as active.
   */
  selectTask(taskId: number): void {
    this.activeTaskId = taskId;
  }
}
