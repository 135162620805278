import { Routes } from '@angular/router';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {HomeComponent} from '../../home/home.component';
import {GardenComponent} from '../../garden/garden.component';
import {UserProfileComponent} from '../../user-profile/user-profile.component';
import {AgendaComponent} from '../../agenda/agenda.component';
import {AgendaTaskComponent} from '../../agenda/agenda-task/agenda-task.component';
import {AgendaArchivesComponent} from '../../agenda/agenda-archives/agenda-archives.component';
import {UserCultivationsComponent} from '../../garden/user-cultivations/user-cultivations.component';
import {UserSeedsComponent} from '../../garden/user-seeds/user-seeds.component';

export const mainLayoutRoutes: Routes = [
  {
    path: '', // Default route for main layout
    component: MainLayoutComponent,
    children: [
      { path: 'home', component: HomeComponent },
      {
        path: 'vegiwise/agenda', // Parent path for agenda
        component: AgendaComponent,
        children: [
          { path: '', redirectTo: 'tasks', pathMatch: 'full' }, // Default child route is 'tasks'
          { path: 'tasks', component: AgendaTaskComponent },   // Task tab
          { path: 'archives', component: AgendaArchivesComponent }, // Archive tab
        ]
      },
      { path: 'garden', component: GardenComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' }, // Default child route is 'tasks'
          { path: 'list', component: UserCultivationsComponent },   // Task tab
          { path: 'seeds', component: UserSeedsComponent }, // Archive tab
        ]
       },
      { path: 'profile', component: UserProfileComponent },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
    ],
  }
];
