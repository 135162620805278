<div class="p-4">
  <vw-header-home [user]="user"></vw-header-home>
  <div class="pb-6 w-full">
    <h2 class="text-primary-title text-2xl font-semibold pb-3">
      Météo du jour
    </h2>
    <div class="flex flex-col gap-1">
      <vw-weather-card
        *ngFor="let card of cards; let i = index"
        [title]="card.title"
        [timeLabelStyle]="card.timeLabelStyle"
        [WeatherForecastLabel]="card.WeatherForecastLabel"
        [label]="card.label"
        [weatherImg]="card.weatherImg"
        [imageUrl]="card.imageUrl"
        [tailwindClasses]="card.tailwindClasses"
        [expanded]="card.expanded"
        (toggle)="onCardToggle($event, i)"
        (cardClick)="onCardClick(card)"
      >
      </vw-weather-card>
    </div>
  </div>
  <div class="pb-6 w-full">
    <!-- Tasks  -->
    <h2 class="text-primary-title text-2xl font-semibold pb-3">{{'home.tasks.title' | translate}}</h2>
    <div *ngFor="let task of tasks">
      <vw-task [task]="task"></vw-task>
    </div>

    <!-- Articles  -->
    <vw-article *ngFor="let article of articles" [article]="article"></vw-article>
  </div>

  <div class="pb-28 w-full">
    <h2 class="text-primary-title text-2xl font-semibold pb-3">
      {{'home.cultivations.title' | translate}}
    </h2>
    <vw-user-cultivations [cultivationList]="cultivations"></vw-user-cultivations>
  </div>
</div>
