import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DUMMY_ADVICE, DUMMY_AgendaTASKS, DUMMY_TASKS } from '../@models/mock-data';
import {Task} from '../@models/task.interface';
import {Article} from '../../home/@models/task.interface';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  constructor() { }
  /**
   * Fetches the list of dummy tasks.
   * @returns An Observable containing an array of Task objects.
   */
  getAgendaArchiveTasks(): Observable<Task[]> {
    return of(DUMMY_TASKS);
  }

  /**
   * Fetches the list of dummy advice.
   * @returns An Observable containing an array of Advice objects.
   */
  getAgendaAdvice(): Observable<Article[]> {
    return of(DUMMY_ADVICE);
  }
  /**
   * Fetches the list of dummy AgendaTask.
   * @returns An Observable containing an array of Advice objects.
   */
  getAgendaTasks(): Observable<Task[]> {
    return of(DUMMY_AgendaTASKS);
  }
}
