import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../@commons/ui-components/shared.module';
import {Seed} from '../@models/seed.interface';
import {PotagerService} from '../@services/potager.service';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'vw-user-seeds', // Selector for the component
  standalone: true, // Indicates that this component is standalone
  imports: [SharedModule, CommonModule, TranslateModule], // Required modules for the component
  templateUrl: './user-seeds.component.html', // Path to the component's HTML template
  styleUrls: ['./user-seeds.component.scss'] // Corrected from styleUrl to styleUrls
})
export class UserSeedsComponent implements OnInit {
  seeds: Seed[] = []; // Array to hold seed data

  constructor(private router: Router, private potagerService: PotagerService) {} // Injecting router and potager service

  /**
   * Lifecycle hook that is called after the component is initialized.
   * Fetches the list of seeds from the service.
   */
  ngOnInit() {
    this.potagerService.getSeeds().subscribe((seeds) => {
      this.seeds = seeds; // Assign the fetched seeds to the component property
    });
  }

  /**
   * Navigates to the seed details route when a seed is selected.
   * @param seed - The seed for which details are to be displayed
   */
  onSelectSeedDetails(seed: Seed) {
    this.router.navigate([`garden/seed/${seed.id}`], { state: { seedId: seed.id } });
    // Navigate to the seed details route and pass the seed ID in the state
  }
}
