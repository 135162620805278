import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import {SharedModule} from '../@commons/ui-components/shared.module';
import {CustomFormComponent} from '../@commons/ui-components/custom-form/custom-form.component';
import {fadeIn} from '../@commons/animations/animations';
import {AccountStats} from './@models/accountStats.interface';
import {UserProfileService} from './@sevices/user-profile.service';

@Component({
  selector: 'vw-user-profile', // Component selector for app-compte
  standalone: true, // Marks this component as standalone
  imports: [SharedModule, TranslateModule, CommonModule, CustomFormComponent], // Modules and components imported for use in this component
  templateUrl: './user-profile.component.html', // HTML template for the component
  styleUrl: './user-profile.component.scss', // Stylesheet for the component
  animations: [fadeIn]

})
export class UserProfileComponent implements OnInit {

  /**
   * Controls the visibility of the bottom sheet.
   * @type {boolean}
   */
  isBottomSheetOpen = false;

  /**
   * Stores the currently selected option.
   * @type {string | null}
   */
  selectedOption: string | null = null;

  /**
   * List of account statistics.
   * @type {AccountStats[]}
   */
  accountStats: AccountStats[] = [];

  /**
   * Holds form data submitted from AccountFormComponent.
   * @type {any}
   */
  formData: any = null;

  /**
   * Progress value for the account statistics display.
   * @type {number}
   */
  progress: number = 50;
  editMode: boolean = false;

  formConfig = {
    title: 'My Account',
    description: 'Update your information below.',
    submitButtonLabel: 'Save Changes',
    fields: [
      {
        name: 'date',
        label: 'Date of Birth',
        type: 'date',
        placeholder: 'Select date',
        required: true,
      },
      {
        name: 'interiorOption',
        label: 'Interior Type',
        type: 'dropdown',
        options: 'interior', // This will select interiorOptions array
        placeholder: 'Choose an option',
        required: true,

      },
      {
        name: 'selectionOption',
        label: 'Selection',
        type: 'dropdown',
        options: 'selection', // This will select selectionOptions array
        placeholder: 'Select an option',
        required: true,

      }
    ],
    defaultValues: {
      date: null,
      interiorOption: null,
      selectionOption: null
    }
  };
  /**
   * Initializes CompteComponent with required services.
   * @param {UserProfileService} compteService - Service to fetch account-related data
   */
  constructor(private compteService: UserProfileService, private router: Router, private route: ActivatedRoute) {}

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   * Fetches account statistics data from CompteService.
   */
  ngOnInit(): void {
    this.fetchAccountStats();
    this.route.queryParams.subscribe(params => {
      if (params['edit'] === 'true') {
        this.editMode = true;
      } else {
        this.editMode = false;
      }
    });
  }

  /**
   * Fetches mock account statistics data from the service and assigns it to accountStats.
   */
  fetchAccountStats(): void {
    this.compteService.getAccountStats().subscribe((stats: AccountStats[]) => {
      this.accountStats = stats;
    });
  }

  /**
   * Toggles the visibility of the bottom sheet.
   */
  toggleBottomSheet(): void {
    this.isBottomSheetOpen = !this.isBottomSheetOpen;
     // Add or remove the 'edit=true' query parameter
     if (this.isBottomSheetOpen) {
      // Open bottom sheet, add `edit=true` to the query params
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { edit: 'true' },
        queryParamsHandling: 'merge',  // Merge with existing query params
      });
    } else {
      // Close bottom sheet, remove `edit` query parameter
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { edit: null },  // Remove the edit query param
        queryParamsHandling: 'merge',  // Merge with existing query params
      });
    }
  }

  /**
   * Closes the bottom sheet by setting isBottomSheetOpen to false.
   */
  closeBottomSheet(): void {
    this.isBottomSheetOpen = false;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { edit: null },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Handles form submission from AccountFormComponent.
   * @param {any} data - Form data received from AccountFormComponent
   */
  handleFormSubmit(data: any): void {
    this.formData = data;
    // Process the form data here (e.g., make API call or store it)
    this.closeBottomSheet(); // Optionally close the sheet after submission
  }
}
