<div class="flex gap-y-4 flex-wrap -mx-2">
  <div *ngFor="let cultivation of cultivations" class="w-2/4 h-full" (click)="openModal(cultivation)">
    <vw-cultivation-item [cultivation]="cultivation" ></vw-cultivation-item>
  </div>
</div>

<vw-dialog
  class="z-[9999]"
  [isOpen]="isModalOpen"
  [width]="'94%'"
  [height]="'auto'"
  [closeIcon]="'fas fa-times-circle'"
  [showCloseIcon]="true"
  [customClasses]="'rounded-lg border shadow-xl'"
  (close)="onModalClose()"
>
  <div modalHeader>
    <div class="w-auto float-end pt-5" (click)="closeModal()">
      <vw-icon-svg
        iconUrl="/assets/svg/close.svg"
        additionalClasses="product-img"
        width="24"
        height="24"
      ></vw-icon-svg>
    </div>
    <h2 class="text-[#0C0D0D] text-[32px] font-semibold pb-3 pt-14">{{ selectedCultivation?.name }}</h2>
  </div>

  <div modalBody>
    <div class="flex flex-wrap justify-center items-center gap-y-4">
      <div class=" text-center w-4/5">
        <ng-container *ngIf="selectedCultivation?.imageUrl; else noImage">
          <!-- <vw-icon-svg
          (click)="onSelectPlant()"
            [iconUrl]="selectedCultivation!.imageUrl"
            additionalClasses="text-center m-auto"
            width="200px"
            height="100%"
          ></vw-icon-svg> -->
          <vw-icon-svg
          (click)="onSelectCultivation()"
            iconUrl="/assets/svg/potager-plant.svg"
            additionalClasses="text-center m-auto"
            width="auto"
            height="100%"
          ></vw-icon-svg>
        </ng-container>
        <ng-template #noImage>
          <vw-icon-svg
            additionalClasses="text-center m-auto"
            width="200px"
            height="100%"
          ></vw-icon-svg>
        </ng-template>
        <!-- <p class="text-xs font-semibold text-black pt-2 text-center">
          {{ selectedCultivation?.variety }}
        </p> -->
      </div>
    </div>
  </div>
</vw-dialog>
