// task.service.ts
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { Article } from '../@models/task.interface';
import { TASKS, ARTICLES, TASK_ITEMS } from '../@models/mock-data';
import {Task} from '../../agenda/@models/task.interface';

@Injectable({
  providedIn: 'root', // Makes the service available throughout the application
})
export class TaskService {
  /**
   * Fetches the list of tasks.
   * @returns {Observable<Task[]>} An observable containing an array of tasks.
   */
  getTasks(): Observable<Task[]> {
    return of(TASKS); // Returns an observable of the TASKS mock data
  }

  /**
   * Fetches the badge information for the application sections.
   * @returns {Observable<BadgeInfo[]>} An observable containing an array of badge information.
   */
  getSectionData(): Observable<Article[]> {
    return of(ARTICLES); // Returns an observable of the BADGE_INFO mock data
  }

  /**
   * Fetches the list of task items.
   * @returns {Observable<Task[]>} An observable containing an array of task items.
   */
  getTaskItems(): Observable<Task[]> {
    return of(TASK_ITEMS); // Returns an observable of the TASK_ITEMS mock data
  }
}
