<!-- expandable-card.component.html -->
<div
  [ngClass]="expanded ? tailwindClasses : ''"
  class="border rounded-[14px] shadow-lg cursor-pointer p-0"
  (click)="onCardClick()"
>
  <!-- Header with optional template projection -->
  <div
    *ngIf="!cardHeaderTemplate"
    [ngClass]="{ 'bg-white relative z-10 shadow-sm': expanded }"
    class="flex justify-between items-center rounded-xl h-12"
    (click)="onToggle($event)"
  >
    <div class="flex items-center">
      <div
        [ngClass]="tailwindClasses"
        class="flex gap-3 p-3 py-3 rounded-l-[10px] items-center"
        *ngIf="!expanded"
      >
        <vw-icon-svg [iconUrl]="imageUrl" width="20"></vw-icon-svg>
        <p
          [ngClass]="WeatherForecastLabel"
          class="text-base font-medium whitespace-nowrap"
        >
          {{ label }}
        </p>
      </div>
      <p class="text-xs pl-3">{{ title }}</p>
    </div>
    <vw-icon-svg
      [ngClass]="{ 'rotate-90': expanded }"
      iconUrl="/assets/svg/right-blue.svg"
      additionalClasses="cursor-pointer mx-4"
      width="8"
    ></vw-icon-svg>
  </div>

  <!-- Card Content (Body) -->
  <div *ngIf="expanded">
    <ng-container *ngIf="cardBodyTemplate">
      <ng-template [ngTemplateOutlet]="cardBodyTemplate"></ng-template>
    </ng-container>
    <!-- <p *ngIf="!cardBodyTemplate">
{{ title }} - This is the default body content.
</p> -->
    <div
      [ngClass]="tailwindClasses"
      class="flex p-3 justify-between rounded-b-xl"
    >
      <div class="flex flex-col gap-1">
        <h5 [ngClass]="timeLabelStyle" class="text-[16px] font-medium">
          Aujourd’hui, 11 juillet
        </h5>
        <p class="text-xs text-[#83A9CC]">Liège, Belgique</p>
        <h3
          [ngClass]="WeatherForecastLabel"
          class="text-[32px] font-semibold leading-tight"
        >
          19°c
        </h3>
        <div class="pt-3">
          <ul class="flex items-center gap-2">
            <li class="flex items-center gap-[2px]">
              <span>
                <vw-icon-svg
                  iconUrl="/assets/svg/weather-arrow-up.svg"
                  additionalClasses="cursor-pointer"
                  width="16"
                  height="16"
                ></vw-icon-svg>
              </span>
              <span [ngClass]="WeatherForecastLabel" class="text-xs">
                19°c</span
              >
            </li>
            <li class="flex items-center gap-[2px]">
              <span>
                <vw-icon-svg
                  iconUrl="/assets/svg/weather-arrow-down.svg"
                  additionalClasses="cursor-pointer"
                  width="16"
                  height="16"
                ></vw-icon-svg>
              </span>
              <span [ngClass]="WeatherForecastLabel" class="text-xs">11°c</span>
            </li>
            <li class="flex items-center gap-[2px]">
              <span>
                <vw-icon-svg
                  iconUrl="/assets/svg/weather-droplet.svg"
                  additionalClasses="cursor-pointer"
                  width="16"
                  height="16"
                ></vw-icon-svg>
              </span>
              <span [ngClass]="WeatherForecastLabel" class="text-xs">22mm</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="">
        <vw-icon-svg
          [iconUrl]="weatherImg"
          additionalClasses="cursor-pointer"
          width="112"
          height="112"
        ></vw-icon-svg>
      </div>
    </div>
  </div>
</div>
