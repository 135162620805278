import { Routes } from '@angular/router';
import {mainLayoutRoutes} from './@commons/layouts/main-layout.routes'; // Import Routes from Angular Router

// Define application routes using lazy loading for feature modules
export const routes: Routes = [
  {
    path: 'splashScreen', // Path for the splash screen
    loadChildren: () =>
      import('./@commons/ui-components/splash-screen/splash-screen.routes'), // Lazy load splash screen routes
  },
  {
    path: 'onboarding', // Path for onboarding
    loadChildren: () => import('./onboarding/onboarding.routes'), // Lazy load onboarding routes
  },
  {
    path: 'auth', // Path for authentication module
    loadChildren: () => import('./Auth/auth.routes'), // Lazy load authentication routes
  },
  {
    path: 'init', // Path for the welcome page
    loadChildren: () => import('./welcome/welcome.routes'), // Lazy load welcome routes
  },
  {
    path: '',
    redirectTo: 'splashScreen', // Default route redirects to 'home'
    pathMatch: 'full',
  },
  ...mainLayoutRoutes, // Include main layout routes here
  {
    path: 'garden', // Path for potager routes
    loadChildren: () => import('./garden/garden.module').then(m => m.GardenModule), // Ensure correct import path
  },
  {
    path: '**',
    redirectTo: 'splashScreen', // Global fallback route to splashScreen
  },
];
