import { AccountStats, User } from "./accountStats.interface";

export const DUMMY_ACCOUNT_STATS: AccountStats[] = [
  { iconUrl: '/assets/svg/leafonly.svg', label: 'Plantest', value: 12 },
  { iconUrl: '/assets/svg/leafonly.svg', label: 'Lorem ipsum', value: '24h35' },
  { iconUrl: '/assets/svg/leafonly.svg', label: 'Lorem ipsum', value: 4 },
  { iconUrl: '/assets/svg/leafonly.svg', label: 'Lorem ipsum', value: 0 }
];



export const DUMMY_USER: User = {
  firstName: 'François !',
  lastName: '',
  greeting: 'Bon retour',
};