import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { SharedModule } from '../../../@commons/ui-components/shared.module';

@Component({
  selector: 'vw-weather-card',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './weather-card.component.html',
  styleUrl: './weather-card.component.scss',
})
export class WeatherCardComponent {
  @Input() title: string = ''; // Title of the card
  @Input() label: string = ''; // label of the card
  @Input() labelClass: string = ''; // labelClass of the card for css
  @Input() weatherImg: string = '';
  @Input() tailwindClasses: string = ''; // Tailwind CSS classes for customization
  @Input() imageUrl: string = '';
  @Input() timeLabelStyle: string = '';
  @Input() WeatherForecastLabel: string = '';
  @Input() expanded: boolean = false; // Initial expansion state of the card

  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>(); // Event emitter to notify parent component about the state change
  @Output() cardClick: EventEmitter<any> = new EventEmitter<any>(); // Event for card click handling

  @ContentChild('cardHeader', { static: false }) cardHeaderTemplate:
    | TemplateRef<any>
    | undefined;
  @ContentChild('cardBody', { static: false }) cardBodyTemplate:
    | TemplateRef<any>
    | undefined;

  // Toggle the expansion of the card
  onToggle(event: any) {
    this.expanded = !this.expanded;
    this.toggle.emit(this.expanded); // Emit the current expanded state
  }

  // Handle card click event and propagate it
  onCardClick() {
    this.cardClick.emit();
  }
}
