import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AccountStats, User } from '../@models/accountStats.interface';
import { DUMMY_ACCOUNT_STATS, DUMMY_USER } from '../@models/mock-data';

@Injectable({
  providedIn: 'root' // Indicates that this service is available application-wide
})
export class UserProfileService {

  constructor() { } // Constructor for the CompteService

  /**
   * Fetches account statistics data.
   * This method simulates an API call by returning mock data.
   *
   * @returns {Observable<AccountStats[]>} - An observable containing an array of account statistics.
   */
  getAccountStats(): Observable<AccountStats[]> {
    return of(DUMMY_ACCOUNT_STATS); // Returns an observable of the dummy account statistics data
  }

  getUserDetails(): Observable<User> {
    return of(DUMMY_USER); // Return an observable of the dummy user details
  }
}
