import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SharedModule} from '../../../@commons/ui-components/shared.module';
import {User} from '../../../user-profile/@models/accountStats.interface';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'vw-header-home',
  standalone: true,
    imports: [
        SharedModule,
        TranslateModule
    ],
  templateUrl: './header-home.component.html',
  styleUrl: './header-home.component.scss'
})
export class HeaderHomeComponent {
  @Input() user: User;
  @Output() onProfileClick: EventEmitter<void> = new EventEmitter<void>();

}
