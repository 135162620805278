import {Component, Input, OnInit} from '@angular/core';
import {SharedModule} from '../../@commons/ui-components/shared.module';
import {CommonModule} from '@angular/common';
import {Cultivation} from '../@models/cultivation.interface';
import {Router} from '@angular/router';
import {PotagerService} from '../@services/potager.service';
import {CultivationItemComponent} from '../@components/cultivation-item/cultivation-item.component';


@Component({
  selector: 'vw-user-cultivations', // Selector for the component
  standalone: true, // Indicates that this component is standalone
  imports: [SharedModule, CommonModule, CultivationItemComponent], // Required modules for the component
  templateUrl: './user-cultivations.component.html', // Path to the component's HTML template
  styleUrls: ['./user-cultivations.component.scss'] // Path to the component's SCSS styles
})
export class UserCultivationsComponent implements OnInit {
  @Input()  cultivationList: Cultivation[] = []
  isModalOpen: boolean = false; // Flag to control modal visibility
  cultivations: Cultivation[] = []; // Array to hold the list of cultivations
  selectedCultivation?: Cultivation; // Currently selected cultivation for modal actions
  constructor(private router: Router, private potagerService: PotagerService,) {} // Injecting the router and potager service

  /**
   * Lifecycle hook that is called after the component is initialized.
   * Fetches the list of cultivations from the service.
   */
  ngOnInit() {
    if (this.router.url === '/garden/list') {
      this.potagerService.getCultivations('potager').subscribe((cultivations) => {
        this.cultivations = cultivations; // Assign the fetched cultivations to the component property
      });
    }else{
      this.cultivations = this.cultivationList
    }
  }

  /**
   * Opens the modal and sets the selected cultivation.
   * @param cultivation - The cultivation to display in the modal
   */
  openModal(cultivation: Cultivation) {
    // Check if varietyType is not 0 and not undefined
    if (cultivation.varietyType !== 0 && cultivation.varietyType !== undefined) {
      this.selectedCultivation = cultivation;  // Set the selected cultivation
      this.isModalOpen = true;      // Show the modal
    } else {
      this.router.navigate([`/garden/cultivation/${cultivation.id}/${cultivation.name.replace(/\s+/g, '')}/${cultivation.id}`], {
        state: {
          cultivationId: cultivation.id,
          varietyType: null,
          name: cultivation.name
        }
      });
    }
  }

  /**
   * Closes the modal and resets the selected cultivation.
   */
  closeModal() {
    this.isModalOpen = false; // Hide the modal
    this.selectedCultivation = undefined; // Reset selected cultivation on close
  }

  /**
   * Handles the modal close action, calls closeModal.
   */
  onModalClose() {
    this.closeModal();
  }

  /**
   * Confirms the action taken in the modal and closes it.
   */
  confirmAction() {
    this.closeModal();
  }

  /**
   * Navigates to the variety cultivation route when a cultivation is selected.
   */
  onSelectCultivation() {
    // Navigate to the updated path structure, including the cultivationname
    this.router.navigate([
      '/garden/cultivation',
      this.selectedCultivation!.id,    // Pass the ID of the selected cultivation
      this.selectedCultivation!.name   // Pass the name of the selected cultivation (as cultivationname)
    ], {
      state: {
        cultivationId: this.selectedCultivation!.id,
        varietyType: this.selectedCultivation!.varietyType,  // Explicitly pass varietyType as well
        name: this.selectedCultivation!.name,  // Pass the cultivation name
      }
    });
  }
}
