<div class="p-4 pb-28">
  <!-- Page title -->
  <h2 class="text-[#062825] text-[32px] font-semibold pb-3">{{ 'agenda.title' | translate }}</h2>

  <!-- Tab component that displays different content based on selected tab -->
  <vw-tab
    [tabs]="tabData"
    (tabSelected)="onTabSelected($event)"
    [activeTab]="activeTab"
  >
    <div class="tab-content">
      <!-- Active tab content will be loaded here -->
      <router-outlet></router-outlet>
    </div>
  </vw-tab>
</div>
