import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {SharedModule} from '../../@commons/ui-components/shared.module';
import {Article} from '../../home/@models/task.interface';
import {AgendaService} from '../@services/agenda.service';
import {Task} from '../@models/task.interface';

@Component({
  selector: 'vw-agenda-archives', // Component selector
  standalone: true, // Indicates this is a standalone component
  imports: [CommonModule, SharedModule, TranslateModule], // Modules required by this component
  templateUrl: './agenda-archives.component.html', // HTML template for the component
  styleUrl: './agenda-archives.component.scss' // Stylesheet for the component
})
export class AgendaArchivesComponent implements OnInit {

  /**
   * List of archived tasks.
   * @type {Task[]}
   */
  tasks: Task[] = [];

  /**
   * List of advices related to the agenda.
   * @type {Article[]}
   */
  Advices: Article[] = [];

  /**
   * Initializes the AgendaArchivesComponent with required services.
   * @param {AgendaService} agendaService - Service to fetch agenda-related data
   */
  constructor(private agendaService: AgendaService) {}

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   * Subscribes to tasks and advices data from AgendaService.
   */
  ngOnInit() {
    this.agendaService.getAgendaArchiveTasks().subscribe((tasks) => {
      this.tasks = tasks;
    });

    this.agendaService.getAgendaAdvice().subscribe((advices) => {
      this.Advices = advices;
    });
  }
}
