import {Component, Input} from '@angular/core';
import {SharedModule} from "../../../@commons/ui-components/shared.module";
import {Cultivation} from '../../@models/cultivation.interface';
import {CultivationPictureViewerComponent} from './cultivation-picture-viewer/cultivation-picture-viewer.component';

@Component({
  selector: 'vw-cultivation-item',
  standalone: true,
  imports: [
    SharedModule,
    CultivationPictureViewerComponent
  ],
  templateUrl: './cultivation-item.component.html',
  styleUrl: './cultivation-item.component.scss'
})
export class CultivationItemComponent {
  @Input() cultivation: Cultivation
}
