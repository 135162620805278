<div>
  <div *ngFor="let task of tasks">
    <vw-task [task]="task"></vw-task>
  </div>

  <div class="pb-6 pt-6">
    <div class="mt-0">
      <h1 class="font-semibold text-2xl text-primary-title">{{'agenda.tabs.current.advices' | translate}}</h1>
    </div>

    <div *ngFor="let advice of Advices" class="pb-6">
      <div class="relative mt-3">
        <!-- Background Image -->
        <vw-icon-svg
          [iconUrl]="advice.imageUrl"
          width="1000"
          height="184"
        ></vw-icon-svg>

        <!-- Metadata icons and labels -->
        <div class="absolute top-3 left-3 flex items-center gap-2">
          <div
            *ngFor="let meta of advice.badges"
            [class]="
              'text-xs font-semibold flex items-center gap-1 py-1 px-2 rounded-xl bg-white text-[#185545] border border-[#63AE81]' +
              ' ' + meta.color + ' border ' + meta.borderColor
            "
          >
            <vw-icon-svg
              [iconUrl]="meta.iconUrl"
              additionalClasses="cursor-pointer"
              width="14"
            ></vw-icon-svg>
            {{ meta.label }}
          </div>
        </div>

        <!-- Title and date range -->
        <div class="absolute bottom-3 left-3 text-white">
          <p class="text-lg font-semibold">{{ advice.title }}</p>
          <p class="text-sm">{{ advice.dateRange }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
