<div class="p-4 pb-28" >
  <h2 class="text-[#062825] text-[32px] font-semibold pb-3">{{"profile.title" | translate}}</h2>
  <div>
    <div class="flex items-center flex-wrap w-auto mb-4">
      <div class="w-full pb-6">
        <vw-card customClasses="bg-white">
          <div class="bg-white shadow-lg py-2 pl-2 pr-4 rounded-xl flex items-start gap-4">
            <div class=" w-[112px]">
              <vw-icon-svg
                iconUrl="/assets/svg/account-cartoon.svg"
                additionalClasses=""
                width="112px"
                height="112px"
              ></vw-icon-svg>
            </div>
            <div class="w-account py-2">
              <div class="flex items-center justify-between w-full">
                <h3 class="text-xl text-primary-dark font-semibold pb-2">{{"profile.infos.title" | translate}}</h3>
                <vw-icon-svg
                  (click)="toggleBottomSheet()"
                  iconUrl="/assets/svg/square-pen.svg"
                  additionalClasses="cursor-pointer"
                  width="24px"
                  height="24px"
                ></vw-icon-svg>
              </div>
              <div class="w-full pb-3">
                <p class="text-[#9BA6A1] text-xs">{{"profile.infos.name" | translate}}</p>
                <p class="text-sm text-primary-dark">{{formData?.interiorOption === null ? 'François Genot': formData.interiorOption}}</p>
              </div>
              <div class="w-full pb-3">
                <p class="text-[#9BA6A1] text-xs">{{"profile.infos.joined" | translate}}</p>
                <p class="text-sm text-primary-dark">{{formData?.date === null ? '24/11/2024': formData.date}}</p>
              </div>
              <div class="w-full pb-3">
                <p class="text-[#9BA6A1] text-xs">{{"profile.infos.user-type" | translate}}</p>
                <p class="text-sm text-primary-dark">{{formData?.selectionOption === null ? 'Ipsum dolor sit amet': formData.selectionOption}}</p>
              </div>
              <div class="w-full">
                <p class="text-[#9BA6A1] text-xs">{{"profile.infos.language" | translate}}</p>
                <p class="text-sm text-primary-dark"></p>
              </div>
            </div>
          </div>
        </vw-card>
      </div>
      <div class="w-full pb-6">
        <h2 class="text-[#062825] text-2xl font-semibold pb-3">
          {{ "profile.level" | translate}}
        </h2>
        <div class="bg-white shadow-lg p-3   rounded-xl">
          <div class="flex items-center gap-2 w-full pb-3">
            <vw-icon-svg
              iconUrl="/assets/svg/leafonly.svg"
              additionalClasses="cursor-pointer"
              width="20px"
              height="20px"
            ></vw-icon-svg>
            <p class="text-base text-primary-dark">jeune pousse</p>
          </div>
          <div class="w-full rounded-full bg-[#F0EFEF] overflow-hidden">
            <div
            [ngClass]="{
              'bg-[#F79400]': progress < 70,
              'bg-[#317756]': progress >= 70
            }"
              [style.height.px]="10"
              [style.width.%]="progress"
            ></div>
          </div>
        </div>
      </div>
      <div class="w-full pb-6 overflow-hidden">
        <h2 class="text-[#062825] text-2xl font-semibold pb-3">{{ "profile.stats" | translate }}</h2>
        <div class="flex items-center flex-wrap gap-y-4 -mx-2">
          <div *ngFor="let stat of accountStats" class="w-2/4 px-2">
            <div class="bg-white shadow-lg p-4 rounded-xl">
              <div class="flex items-center gap-2 w-full pb-1">
                <vw-icon-svg
                  [iconUrl]="stat.iconUrl"
                  additionalClasses="min-w-[24px] w-[24px]"
                  width="24px"
                  height="24px"
                ></vw-icon-svg>
                <h3 class="text-[40px] leading-none text-primary-dark">{{ stat.value }}</h3>
              </div>
              <p class="text-base text-primary-dark">{{ stat.label }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<vw-custom-form  [isOpen]="editMode"
[formConfig]="formConfig"
(closeBottomSheet)="closeBottomSheet()"
(submitForm)="handleFormSubmit($event)"></vw-custom-form>

