<div class="fixed bottom-0 w-full p-4 z-[999] bottom-bar">
  <div class="flex justify-between p-1 shadow-lg rounded-2xl z-[999] bg-img">
    <div class="flex gap-1">
      <button
        (click)="changeScreen('home')"
        [ngClass]="currentScreen === 'home' ? 'bg-[#C3E5CE]' : ''"
        class="px-4 py-1 h-14 w-[68px] rounded-2xl"
      >
        <vw-icon-svg
          iconUrl="/assets/svg/home.svg"
          [ngClass]="{'opacity-100': currentScreen === 'home','opacity-40': currentScreen !== 'home'}"
          additionalClasses="cursor-pointer m-auto"
          width="24"
          alt="Left Arrow"
        ></vw-icon-svg>
        <span class="text-xs"
              [ngClass]="{'text-primary font-bold': currentScreen === 'home','text-secondary': currentScreen !== 'home'}">
          {{'components.app-menu.home' | translate}}
        </span>
      </button>
      <button
        (click)="changeScreen('vegiwise/agenda')"
        [ngClass]="currentScreen === 'vegiwise/agenda' ? 'bg-[#C3E5CE]' : ''"
        class="px-4 py-1 h-14 w-[68px] rounded-2xl"
      >
        <vw-icon-svg
          iconUrl="/assets/svg/task.svg"
          [ngClass]="{'opacity-100': currentScreen === 'vegiwise/agenda','opacity-40': currentScreen !== 'vegiwise/agenda'}"
          additionalClasses="cursor-pointer m-auto"
          width="24"
          alt="Left Arrow"
        ></vw-icon-svg>
        <span class="text-xs"
              [ngClass]="{'text-primary font-bold': currentScreen === 'vegiwise/agenda','text-secondary': currentScreen !== 'vegiwise/agenda'}">
          {{'components.app-menu.agenda' | translate}}
        </span>
      </button>
    </div>
    <div
      class="w-[70px] h-[70px] bg-transparent rounded-full -mt-[27px] absolute z-[999] left-0 right-0 m-auto flex justify-center items-center"
    >
      <div
        class="w-[60px] h-[60px] bg-primary rounded-full z-[999] circle-clip"
      ></div>
    </div>
    <div class="flex gap-1">
      <button
        (click)="changeScreen('garden')"
        [ngClass]="currentScreen === 'garden' ? 'bg-[#C3E5CE]' : ''"
        class="px-4 py-1 h-14 w-[68px] rounded-2xl"
      >
        <vw-icon-svg
          iconUrl="/assets/svg/garden.svg"
          [ngClass]="{'opacity-100': currentScreen === 'garden','opacity-40': currentScreen !== 'garden'}"
          additionalClasses="cursor-pointer m-auto"
          width="24"
          alt="Left Arrow"
        ></vw-icon-svg>
        <span class="text-xs"
              [ngClass]="{'text-primary font-bold': currentScreen === 'garden','text-secondary': currentScreen !== 'garden'}">
          {{'components.app-menu.garden' | translate}}
        </span>
      </button>
      <button
        (click)="changeScreen('profile')"
        [ngClass]="currentScreen === 'profile' ? 'bg-[#C3E5CE]' : ''"
        class="px-4 py-1 h-14 w-[68px] rounded-2xl"
      >
        <vw-icon-svg
          iconUrl="/assets/svg/account.svg"
          [ngClass]="{'opacity-100': currentScreen === 'profile','opacity-40': currentScreen !== 'profile'}"
          additionalClasses="cursor-pointer m-auto"
          width="24"
          alt="Left Arrow"
        ></vw-icon-svg>
        <span class="text-xs"
              [ngClass]="{'text-primary font-bold': currentScreen === 'profile','text-secondary': currentScreen !== 'profile'}">
          {{'components.app-menu.profile' | translate}}
        </span>
      </button>
    </div>
  </div>
</div>

