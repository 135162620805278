<div class="px-2 h-full" >
  <vw-card customClasses="bg-white h-full">
    <div class="rounded-xl flex justify-center flex-col  w-full p-[8px]">
      <div class="w-full  relative">
        <vw-cultivation-picture-viewer [cultivation]="cultivation" ></vw-cultivation-picture-viewer>
        @if ( cultivation.actionRequired === true) {
          <div class="flex items-center absolute bottom-[4px] error-msg w-full gap-1">
            <vw-icon-svg
              iconUrl="/assets/svg/info.svg"
              additionalClasses="cursor-pointer z-[9] relative ml-2"
              width="14"
              height="14"
            ></vw-icon-svg>
            <span class="text-[#C5132E] font-semibold text-xs z-[9]">
                {{ cultivation.actionRequired ? 'Action à réaliser' : 'Aucune action requise' }}
              </span>
          </div>
        }
      </div>

      <div class="mt-2 w-full">
        <h3 class="text-xl font-semibold text-[#023E42]">
          {{ cultivation.name }}
        </h3>
        <p class="text-xs font-normal text-[#9DA4A2] flex  items-center text-left justify-start leading-5">
          <vw-icon-svg
            [iconUrl]="cultivation.icon!"
            additionalClasses="cursor-pointer z-[9] relative mr-2"
            width="18"
            height="18"
          ></vw-icon-svg>
          {{ cultivation.variety }}
        </p>
      </div>
    </div>
  </vw-card>
</div>
