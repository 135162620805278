import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {SharedModule} from '../@commons/ui-components/shared.module';
import {fadeIn} from '../@commons/animations/animations';

@Component({
  selector: 'vw-agenda',
  standalone: true,
  imports: [CommonModule, SharedModule,RouterOutlet, TranslateModule],
  templateUrl: './agenda.component.html',
  styleUrl: './agenda.component.scss',
  animations: [fadeIn]
})
export class AgendaComponent implements OnInit {
  activeTab = 0;

  tabData = [
    { label: 'agenda.tabs.current.tab_title', labelPayload: {tasksCount: 3},
      icon: 'fas fa-home', tailwindClasses: 'text-sm', width: '100%', border: 'rounded-full' },
      { label: 'agenda.tabs.previous.tab_title', labelPayload: {}, icon: 'fas fa-search', tailwindClasses: 'text-sm', width: '100%', border: 'rounded-full' },
  ];

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    // Listen to route changes and update activeTab accordingly
    this.route.firstChild?.url.subscribe((urlSegments) => {
      const routePath = urlSegments[0]?.path; // This will give us 'tasks' or 'archives'
      if (routePath === 'tasks') {
        this.activeTab = 0;
      } else if (routePath === 'archives') {
        this.activeTab = 1;
      }
    });
  }

  onTabSelected(index: number): void {
    this.activeTab = index;

    // Navigate to the appropriate route based on the selected tab index
    if (index === 0) {
      this.router.navigate(['/vegiwise/agenda/tasks']);
    } else if (index === 1) {
      this.router.navigate(['/vegiwise/agenda/archives']);
    }
  }
}
