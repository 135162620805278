import {Component, Input} from '@angular/core';
import {SharedModule} from '../../../../@commons/ui-components/shared.module';
import {Cultivation} from '../../../@models/cultivation.interface';

@Component({
  selector: 'vw-cultivation-picture-viewer',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './cultivation-picture-viewer.component.html',
  styleUrl: './cultivation-picture-viewer.component.scss'
})
export class CultivationPictureViewerComponent {
  @Input() cultivation!: Cultivation;

}
