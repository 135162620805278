<div>
<!-- agenda-archives.component.html -->
<div *ngFor="let task of tasks">
  <vw-card customClasses="bg-white rounded-xl flex gap-4 p-4 mb-3">
    <!-- Task icon -->
    <vw-icon-svg *ngIf="task.iconUrl"
      [iconUrl]="task.iconUrl"
      additionalClasses="cursor-pointer"
      width="18"
    ></vw-icon-svg>

    <div>
      <!-- Task name -->
      <p class="text-lg font-semibold leading-none pb-[2px] text-[#0C0D0D]">
        {{ task.title }}
      </p>
      <!-- Task description -->
      <p class="text-[#7A8581] text-sm">
        {{ task.description }}
      </p>

      <!-- Task metadata icons and labels -->
      <div class="flex items-center gap-1 mt-2">
        <div
          *ngFor="let meta of task.metadata"
          [class]="
            'text-xs font-semibold flex items-center gap-2 py-1 px-2 w-auto rounded-2xl' +
            ' ' + meta.color + ' border ' + meta.borderColor
          "
        >
          <vw-icon-svg *ngIf="meta.iconUrl"
            [iconUrl]="meta.iconUrl"
            additionalClasses="cursor-pointer"
            width="14"
          ></vw-icon-svg>
          {{ meta.label }}
        </div>
      </div>
    </div>
  </vw-card>
</div>
 <!-- advice.component.html -->
  <div class="mt-3">
    <h1 class="font-semibold text-2xl">{{'agenda.tabs.previous.advices' | translate}}</h1>
  </div>
  <div *ngFor="let advice of Advices" class="pb-6">
  <div class="relative mt-3">
    <!-- Background Image -->
    <vw-icon-svg
      [iconUrl]="advice.imageUrl"
      width="1000"
      height="184"
    ></vw-icon-svg>

    <!-- Metadata icons and labels -->
    <div class="absolute top-3 left-3 flex items-center gap-2">
      <div
        *ngFor="let meta of advice.badges"
        [class]="
          'text-xs font-semibold flex items-center gap-2 py-1 px-2 rounded-xl bg-white' +
          ' ' + meta.color + ' border ' + meta.borderColor
        "
      >
        <vw-icon-svg
          [iconUrl]="meta.iconUrl"
          additionalClasses="cursor-pointer"
          width="14"
        ></vw-icon-svg>
        {{ meta.label }}
      </div>
    </div>

    <!-- Title and date range -->
    <div class="absolute bottom-3 left-3 text-white">
      <p class="text-lg font-semibold">{{ advice.title }}</p>
      <p class="text-sm">{{ advice.dateRange }}</p>
    </div>
  </div>
</div>
  </div>
