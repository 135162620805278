import {Component, OnInit} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {MockConfig} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/config/mock-config';
import {SdkConfigService} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/config/sdk-config.service';
import {environment} from '../environments/environment';
import {StorageHelper} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/@common/helpers/storage.helper';

@Component({
  selector: 'vw-root',
  standalone: true,
  imports: [RouterOutlet, TranslateModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
  }

  ngOnInit(): void {
    const mockConfig : MockConfig = {
      dms: true,
      tasks: false,
      plants: false,
      cultivations: false,
      'cultivation-periods': false,
      'botanic-families': true,
      'growing-steps': false,
      'plants-to-growing-steps': false,
      'user-suggestions': false,
      risks: true,
      social: true,
      user: false,
      users: false,
      weather: false,
      locations: false,
      notifications: true
    };
    const sdkConfig: SdkConfigService = SdkConfigService.configure({
      baseUrl: environment.API_BASE,
      apiKey: "",
      mockConfig,
      storeOnMobile: true,
      accessTokenName: "access_token",
      refreshTokenName: "refresh_token",
    });
    sdkConfig.setMock(true, mockConfig);

    StorageHelper.getInstance().getMobileStorageItem('access_token').then((res) => {
      console.log('JE lAI EUUUU' + res);
      StorageHelper.getInstance().setItem('access_token', res ||'');
    });
    StorageHelper.getInstance().getMobileStorageItem('refresh_token').then((res) => {
      console.log('JE lAI EUUUU' + res);
      StorageHelper.getInstance().setItem('refresh_token', res || '');
    });
  }
}
