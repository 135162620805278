import { AgendaAdvice } from "./advice.interface";
import {Task} from './task.interface';
import {Article} from '../../home/@models/task.interface';

export const DUMMY_TASKS: Task[] = [
  {
    id: 1,
    title: 'Nom de la tâche',
    description: 'Description brève de la tâche',
    iconUrl: '/assets/svg/checkbox-circle-line.svg',
    sourceIconUrl: '/assets/svg/hexagon.svg',
    timelineIconUrl: '/assets/svg/calendar.svg',
    metadata: [
      {
        iconUrl: '/assets/svg/hexagon.svg',
        label: 'Source',
        color: '#565D5B',
        borderColor: '#C5C9C8',
      },
      {
        iconUrl: '/assets/svg/calendar.svg',
        label: 'Cette semaine',
        color: '#C5132E',
        borderColor: '#C5132E',
      },
    ],
  },
  {
    id: 2,
    title: 'Nom de la tâche 2',
    description: 'Description de la deuxième tâche',
    iconUrl: '/assets/svg/checkbox-circle-line.svg',
    sourceIconUrl: '/assets/svg/hexagon.svg',
    timelineIconUrl: '/assets/svg/calendar.svg',
    metadata: [
      {
        iconUrl: '/assets/svg/hexagon.svg',
        label: 'Source',
        color: '#565D5B',
        borderColor: '#C5C9C8',
      },
      {
        iconUrl: '/assets/svg/calendar.svg',
        label: 'Aujourd\'hui',
        color: '#C5132E',
        borderColor: '#C5132E',
      },
    ],
  },
  // Additional tasks as needed
];

export const DUMMY_ADVICE: Article[] = [
  {
    title: 'Attention aux limaces',
    subTitle: 'Septembre, Octobre',
    imageUrl: '/assets/svg/greenIMG.svg',
    iconUrl: '',
    badges: [
      {
        iconUrl: '/assets/svg/hexagongreen.svg',
        label: 'Source',
        color: '#63AE81',
        borderColor: '#63AE81',
      },
      {
        iconUrl: '/assets/svg/hexagongreen.svg',
        label: 'Choux',
        color: '#63AE81',
        borderColor: '#63AE81',
      },
    ],
    dateRange: 'Septembre, Octobre',
  },
  // Additional advice items as needed
];

export const DUMMY_AgendaTASKS: Task[] = [
  {
    id: 1,
    title: 'Nom de la tâche 1',
    description: 'Description brève de la tâche 1',
    source: 'Source',
    timeline: 'Cette semaine',
    sourceIconUrl: '/assets/svg/hexagon.svg',
    timelineIconUrl: '/assets/svg/calendar.svg'
  },
  {
    id: 2,
    title: 'Nom de la tâche 2',
    description: 'Description brève de la tâche 2',
    source: 'Source',
    timeline: 'Cette semaine',
    sourceIconUrl: '/assets/svg/hexagon.svg',
    timelineIconUrl: '/assets/svg/calendar.svg'
  },
  {
    id: 3,
    title: 'Nom de la tâche 3',
    description: 'Description brève de la tâche 3',
    source: 'Source',
    timeline: 'Cette semaine',
    sourceIconUrl: '/assets/svg/hexagon.svg',
    timelineIconUrl: '/assets/svg/calendar.svg'
  }
];
