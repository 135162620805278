// src/app/layouts/main-layout/main-layout.component.ts


import {Component, OnInit} from '@angular/core';
import {AppMenuComponent} from '../../@components/app-menu/app-menu.component';
import {CommonModule} from '@angular/common';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {fadeIn, right} from '../../../@commons/animations/animations';
import {NavigationService} from '../../../@commons/services/navigation.service';

@Component({
  selector: 'vw-main-layout',
  standalone: true,
  imports: [AppMenuComponent, CommonModule, RouterOutlet],
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [fadeIn, right], // Register the animations
})
export class MainLayoutComponent implements OnInit {
  currentRoute: string = 'home';
  previousIndex: number = -1; // Track the previous index

  constructor(private router: Router, private navigationService: NavigationService) {}

  ngOnInit() {
    this.navigationService.currentScreen$.subscribe(screen => {
      this.currentRoute = screen; // Update the current route based on the service
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const route = event.urlAfterRedirects.startsWith('/') ? event.urlAfterRedirects.slice(1) : event.urlAfterRedirects;

        if (this.navigationService.hasScreen(route)) {
          const currentIndex = this.navigationService.getCurrentIndex();
          const nextIndex = this.navigationService.getScreenIndexMap()[route];

          if (nextIndex !== undefined) {
            this.previousIndex = currentIndex; // Store current index as previous
            this.navigationService.changeScreen(route);
            this.currentRoute = route; // Update current route
          }
        }
      }
    });

    const storedScreen = this.navigationService.getStoredScreen();
    if (storedScreen) {
      this.navigationService.changeScreen(storedScreen);
    }
  }

  onScreenChange(screen: string) {
    this.navigationService.changeScreen(screen);
    this.router.navigate([screen]);
  }

  getRouterAnimationState() {
    const currentIndex = this.navigationService.getCurrentIndex();
    return currentIndex > this.previousIndex ? 'increment' : 'decrement'; // Determine the animation state
  }
}
