import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {fadeIn} from '../@commons/animations/animations';
import {Plant} from './@models/plant.interface';
import {SharedModule} from '../@commons/ui-components/shared.module';

@Component({
  selector: 'vw-garden', // Selector for the potager component
  standalone: true, // Indicates that this component is standalone
  imports: [CommonModule, SharedModule,RouterOutlet, TranslateModule], // Required modules and components
  templateUrl: './garden.component.html', // Path to the component's HTML template
  styleUrls: ['./garden.component.scss'], // Path to the component's SCSS styles
  animations: [fadeIn]
})
export class GardenComponent {
  activeTab = 0; // Default tab index (0 for the first tab)
  plants: Plant[] = []; // Array to hold the list of plants
  constructor(private route: ActivatedRoute, private router: Router) {} // Constructor to inject the TaskService

  /**
   * Array containing data for the tabs.
   * Each object represents a tab with its label, icon, and styling classes.
   * @type {Array<{ label: string; icon: string; tailwindClasses: string; width: string; border: string; }>}
   */
  tabData = [
    { label: 'garden.tabs.cultivations.tab_title', labelPayload: {cultivationsCount: 3},
      icon: 'fas fa-home', tailwindClasses: 'text-sm', width: '100%', border: 'rounded-full' },
    { label: 'garden.tabs.seeds.tab_title', icon: 'fas fa-search', tailwindClasses: 'text-sm', width: '100%', border: 'rounded-full' },
  ];



  ngOnInit() {
    this.route.firstChild?.url.subscribe((urlSegments) => {
      const routePath = urlSegments[0]?.path;
      if (routePath === 'list') {
        this.activeTab = 0;
      } else if (routePath === 'seeds') {
        this.activeTab = 1;
      }
    });
  }

    /**
   * Method to handle tab selection.
   * Updates the activeTab index based on the selected tab index.
   * @param {number} index - The index of the selected tab.
   */
    onTabSelected(index: number) {
      this.activeTab = index; // Set the active tab to the selected index
        // Navigate to the appropriate route based on the selected tab index
        if (index === 0) {
          this.router.navigate(['/garden/list']);
        } else if (index === 1) {
          this.router.navigate(['/garden/seeds']);
        }
    }
}
